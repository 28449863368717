<template>
  <div class="contontaa">
    <div :style="{ width: '6rem', height: '2.9rem' }" ref="myChart"></div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        // 订单统计：192.168.0.115:8001/api/admin/datacore/order_statistics
        // (注：xAxis：坐标，order_num：总订单数，to_be_paid：待付款数，to_be_shipped：待收货数，shipped：代发货数，goods_received：已收货数）
        xAxis: [],
        order_num: [],
        to_be_paid: [],
        to_be_shipped: [],
        shipped: [],
        goods_received: []
      }
    },
    methods: {
      getEchartData() {
        var myChart = this.$echarts.init(this.$refs.myChart);

        var option = {
          tooltip: {
            trigger: 'axis',
            backgroundColor: ' rgba(255,255,255, 0.9)', // 提示框背景色
            borderColor: '#333',
            axisPointer: { // 坐标轴指示器，坐标轴触发有效
              type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'

            }
          },
          grid: {
            left: '2%',
            right: '4%',
            bottom: '14%',
            top: '16%',
            containLabel: true
          },
          legend: {
            data: ['已收货数', '总订单数', '代发货数', '待付款数', '待收货数'],
            right: 10,
            top: 12,
            textStyle: {
              color: "#fff"
            },
            itemWidth: 12,
            itemHeight: 10,
            // itemGap: 35
          },
          xAxis: {
            type: 'category',
            data: this.xAxis, //x轴坐标
            axisLine: {
              lineStyle: {
                color: 'white'

              }
            },
            axisLabel: {
              // interval: 0,
              // rotate: 40,
              textStyle: {
                fontFamily: 'Microsoft YaHei'
              }
            },
          },

          yAxis: {
            type: 'value',
            max: '1200',
            axisLine: {
              show: false,
              lineStyle: {
                color: 'white'
              }
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: 'rgba(255,255,255,0.3)'
              }
            },
            axisLabel: {}
          },
          "dataZoom": [{
            "show": false,
          }, {
            "type": "inside",
            "show": true,
            "height": 15,
            "start": 1,
            "end": 35
          }],
          series: [{
              name: '待付款数',
              type: 'bar',
              barWidth: '15%',
              itemStyle: {
                normal: {
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#fccb05'
                  }, {
                    offset: 1,
                    color: '#f5804d'
                  }]),
                  barBorderRadius: 12,
                },
              },
              data: this.to_be_paid
            },
            {
              name: '总订单数',
              type: 'bar',
              barWidth: '15%',
              itemStyle: {
                normal: {
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#8bd46e'
                  }, {
                    offset: 1,
                    color: '#09bcb7'
                  }]),
                  barBorderRadius: 11,
                }

              },
              data: this.order_num
            },
            {
              name: '已收货数',
              type: 'bar',
              barWidth: '15%',
              itemStyle: {
                normal: {
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#248ff7'
                  }, {
                    offset: 1,
                    color: '#6851f1'
                  }]),
                  barBorderRadius: 11,
                }
              },
              data: this.goods_received
            }
          ]
        };

        var app = {
          currentIndex: -1,
        };
        setInterval(function() {
          var dataLen = option.series[0].data.length;

          // 取消之前高亮的图形
          myChart.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
            dataIndex: app.currentIndex
          });
          app.currentIndex = (app.currentIndex + 1) % dataLen;
          //console.log(app.currentIndex);
          // 高亮当前图形
          myChart.dispatchAction({
            type: 'highlight',
            seriesIndex: 0,
            dataIndex: app.currentIndex,
          });
          // 显示 tooltip
          myChart.dispatchAction({
            type: 'showTip',
            seriesIndex: 0,
            dataIndex: app.currentIndex
          });
        }, 1000);

        myChart.setOption(option);

      },
      getordernum() {
        this.$axios({
          url: this.HOST + 'api/admin/datacore/order_statistics',
          method: 'get',
        }).then(res => {
          let data = res.data.data
          this.goods_received = data.goods_received //已收货数
          this.order_num = data.order_num //总订单数
          this.shipped = data.shipped //代发货数
          this.to_be_paid = data.to_be_paid //待付款数
          this.to_be_shipped = data.to_be_shipped //待收货数
          this.xAxis = data.xAxis //x轴坐标
          this.getEchartData();
        })
      }
    },
    mounted() {
      this.getordernum()
      this.getEchartData();
    },
  }
</script>

<style scoped>
  .contontaa {
    z-index: 999;
    width: 6rem;
    height: 2.8rem;
    position: relative;
    top: -3rem;
  }
</style>
