<template>
  <div class="contontaa">
    <div :style="{ width: '6rem', height: '2.9rem' }" ref="myChart"></div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
       // (注：xAxis：坐标，user_join：总加入人数，app_user：app用户，mp_user：小程序用户，weixin_user：微信公众号用户，web_user：H5用户)
       user_join:[],
       app_user:[],
       xAxis:[],
       mp_user:[],
       weixin_user:[],
       web_user:[]
              
      }
    },
    methods: {
    
      getEchartData() {
        let myChart = this.$echarts.init(this.$refs.myChart);

          
        let dataValue = [20, 30, 40, 35, 34, 15, 56, 15, 12, 25, 34, 42];
        let dataValue1 = [40, 35, 34, 15, 56, 15, 12, 25, 34, 42, 20, 30, ];
        let option = {
          tooltip: {
            trigger: 'axis',
            backgroundColor: ' rgba(255,255,255, 0.9)', // 提示框背景色
              borderColor: '#333', 
            axisPointer: {
             
              lineStyle: {
                color: 'rgb(126,199,255)',
              },
            },
          },
          legend: {
            show: true,
            top: '10%',
            itemWidth: 30, // 图例标记的图形宽度。
            //   itemGap: 20, // 图例每项之间的间隔。
            itemHeight: 10, //  图例标记的图形高度。
            textStyle: {
              color: '#fff',
              fontSize: 14,
              padding: [0, 8, 0, 8]
            }
          },
          grid: {
            top: '30%',
            left: '10%',
            right: '5%',
            bottom: '20%',
          },
          xAxis: [{
            type: 'category',
            boundaryGap: false,
            axisLine: {
              //坐标轴轴线相关设置。数学上的x轴
              show: true,
              lineStyle: {
                color: 'rgb(41,188,245)',
              },
            },
            axisLabel: {
              //坐标轴刻度标签的相关设置
              textStyle: {
                color: '#FFFFFF',
                fontSize: 12,
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: '#233653',
              },
            },
            axisTick: {
              show: false,
            },
            data:this.xAxis,
    
          }, ],
          yAxis: [{
            name: "",
            nameTextStyle: {
              color: "#fff",
              fontSize: 12,
              padding: [0, 60, 0, 0]
            },
            // minInterval: 1,
            type: 'value',
            splitLine: {
              show: true,
              lineStyle: {
                color: '#1160a0',
                type: 'dashed'
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#008de7',
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: '#fff',
                fontSize: 14
              }
            },
            axisTick: {
              show: false,
            },
          }, ],
          series: [{
              name: '已处置数',
              type: 'line',
              symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
              smooth: true,
              lineStyle: {
                normal: {
                  width: 3,
                  color: '#00ffa2', // 线条颜色
                },
              },
              itemStyle: {
                normal: {
                  color: '#00f0ff', //拐点颜色
                  // borderColor: '#fff600',//拐点边框颜色
                  // borderWidth: 13//拐点边框大小
                  label: {
                    show: true, //开启显示
                    color: '#fff',
                    position: 'top', //在上方显示
                    formatter: function(res) {
                      if (res.value) {
                        return res.value
                      } else {
                        return 0
                      }
                    },
                  },
                },

              },
              symbolSize: 8, //设定实心点的大小
              areaStyle: {
                normal: {
                  //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                  color: new this.$echarts.graphic.LinearGradient(
                    0,
                    0,
                    0,
                    1,
                    [{
                        offset: 0,
                        color: '#00ffa230',
                      },
                      {
                        offset: 0.6,
                        color: '#00ffa220',
                      },
                      {
                        offset: 1,
                        color: '#00ffa210',
                      },
                    ],
                    false
                  ),
                },
              },
              data:this.user_join,
            },
            {
              name: '预警次数',
              type: 'line',
              symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
              smooth: true,
              lineStyle: {
                normal: {
                  width: 3,
                  color: '#ffba00', // 线条颜色
                },
              },
              itemStyle: {
                normal: {
                  color: '#fff600', //拐点颜色
                  // borderColor: '#fff600',//拐点边框颜色
                  // borderWidth: 13//拐点边框大小
                  label: {
                    show: true, //开启显示
                    color: '#fff',
                    position: 'top', //在上方显示
                    formatter: function(res) {
                      if (res.value) {
                        return res.value
                      } else {
                        return 0
                      }
                    },
                  },
                },

              },
              symbolSize: 8, //设定实心点的大小
              areaStyle: {
                normal: {
                  //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                  color: new this.$echarts.graphic.LinearGradient(
                    0,
                    0,
                    0,
                    1,
                    [{
                        offset: 0,
                        color: '#ffba0030',
                      },
                      {
                        offset: 0.6,
                        color: '#ffba0020',
                      },
                      {
                        offset: 1,
                        color: '#ffba0010',
                      },
                    ],
                    false
                  ),
                },
              },
              data: this.web_user,
            }
          ]
        }

        var app = {
          currentIndex: -1,
        };
        setInterval(function() {
          var dataLen = option.series[0].data.length;

          // 取消之前高亮的图形
          myChart.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
            dataIndex: app.currentIndex
          });
          app.currentIndex = (app.currentIndex + 1) % dataLen;
          //console.log(app.currentIndex);
          // 高亮当前图形
          myChart.dispatchAction({
            type: 'highlight',
            seriesIndex: 0,
            dataIndex: app.currentIndex,
          });
          // 显示 tooltip
          myChart.dispatchAction({
            type: 'showTip',
            seriesIndex: 0,
            dataIndex: app.currentIndex
          });
        }, 1000);

        myChart.setOption(option);
      },
      getuser(){
        this.$axios({
                      url: this.HOST + '/api/admin/datacore/user_seven_increase',
                      method: 'get',
                    }).then(res => {
                      console.log(res)
                      let ress =res.data.data
                      this.xAxis=ress.xAxis 
                      this.app_user=ress.app_user
                      this.mp_user =ress.mp_user
                      this.user_join =ress.user_join
                      this.web_user  =ress.web_user
                      this.weixin_user =ress.weixin_user
                      this.getEchartData()
                        //res为后端传回来的数据
        })
      }
    },
    mounted() {
        this.getuser()
      this.getEchartData()
    
    }
  }
</script>

<style scoped>
  .contontaa {
    z-index: 999;
    width: 6rem;
    height: 2.8rem;
    position: relative;
     top: -3rem;
  }
</style>
