<template>
  <div class="contont">
    <div class='contont_one'>
    <Four>
     
    </Four>
     <EOne ></EOne>
   
    </div>
    <div class='contont_two'>
      <Four>
       
      </Four>
       <Etwo></Etwo>
    </div>
    <div class='contont_three'>
      <Four></Four>
      <three></three>
    </div>
  </div>
</template>

<script>
  import Four from "../fourjiao/fourjiao.vue"
  import EOne from "../eachers/leftone.vue"
   import Etwo from "../eachers/lefttwo.vue"
  import three from "../eachers/leftthree.vue"
  export default{
    components:{
      Four,Etwo,EOne,three
    },
    data() {
      return{
        
      }
    }
  }
</script>

<style scoped>
  .contont {
    width: 6rem;
    height: 9.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .contont .contont_one {
    width: 6rem;
    background-color: aliceblue;
    height: 33.3%;
    background-color: rgba(255, 255, 255, 0.03);
    background-image: url('../../../public/images/line(1).png');
    margin-bottom: 0.25rem;

  }

  .contont .contont_two {
    background-color: rgba(255, 255, 255, 0.03);
    width: 6rem;
    height: 33.3%;
    background-image: url('../../../public/images/line(1).png');
     margin-bottom: 0.25rem;
  }

  .contont .contont_three {
    background-image: url('../../../public/images/line(1).png');
    background-color: rgba(255, 255, 255, 0.03);
    width: 6rem;
    height: 33.3%;
  }

</style>
