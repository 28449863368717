<template>
  <div id="app">
    <BJ></BJ>
    <!-- 左边部分 -->
    <div class="left" style="float: left;">
      <ONE></ONE>
    </div>
    <!-- 中间部分 -->
    <div class="middle">
      <MIDDLE></MIDDLE>
    </div>
    <!-- 右边部分 -->
    <div class="right" style="float: right;">
      <TWO></TWO>
    </div>

    <router-view />
  </div>
</template>

<script>
  import BJ from "./components/beijing/beijing.vue"
  import ONE from "./components/moduleone/one.vue"
  import TWO from "./components/moduletwo/moduleright.vue"
  import  MIDDLE from "./components/modulemiddle/middle.vue"
  export default {
    components: {
      BJ,
      ONE,
      TWO,MIDDLE
    },
    data() {
      return {
           poiInfo:{}
      }
    },
    created() {
      // this.getaxios()
    },
   //  methods:{
   //    getaxios(){
   //      this.$axios({
   //                    url: this.HOST + '/api/admin/datacore/map_ship_address',
   //                    method: 'get',
   //                  }).then(res => {
   //                    console.log(res,11111)
   //                      //res为后端传回来的数据
   //      })
   
   //    }
   //  }
  }
</script>







<style scoped>
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  #app {
    position: relative;
    z-index: 1;
  }

  .left {
    position: absolute;
    top: 1.25rem;
    left: 0.25rem;
  }
  .right{
    position: absolute;
    top: 1.25rem;
    right: 0.25rem;
  }
  .middle{
    position: absolute;
    top: 1.25rem;
    right: 6.6rem;
  }
</style>
