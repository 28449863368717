<template>
  <div class="contontaa">
    <div :style="{ width: '10rem', height: '8rem' }" ref="myChart"></div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
             getaddresss:[]
      }
    },
    methods: {
     getaddress(){
       this.$axios({
                     url: this.HOST + 'api/admin/datacore/map_ship_address',
                     method: 'get',
                   }).then(res => {
                     let ress =res.data.data
                     this.address = ress
                    var arrNew=[]
                   arrNew =this.address.map((item)=>{
                      return{
                        name:item.city,
                        value:item.goods_num
                      }
                    })
                      this.getaddresss=arrNew
                     console.log(  this.getaddresss ,1111)
                     this.getEchartData()
               //res为后端传回来的数据
       })
     },
      getEchartData() {
        var myChart = this.$echarts.init(this.$refs.myChart);

        var data = this.getaddresss;

        var geoCoordMap = {
          "广东": [113.23, 23.16],
          "山东": [117, 36.65],
          "河南": [113.65, 34.76],
          "四川": [104.06, 30.67],
          "江苏": [118.78, 32.04],
          "河北": [114.48, 38.03],
          "湖南": [113, 28.21],
          "安徽": [117.27, 31.86],
          "湖北": [114.31, 30.52],
          "浙江": [120.19, 30.26],
          "广西": [108.33, 22.84],
          "云南": [102.73, 25.04],
          "江西": [115.89, 28.68],
          "辽宁": [123.38, 41.8],
          "福建": [119.3, 26.08],
          "陕西": [108.95, 34.27],
          "黑龙江": [126.63, 45.75],
          "山西": [112.53, 37.87],
          "贵州": [106.71, 26.57],
          "重庆": [106.54, 29.59],
          "吉林": [125.35, 43.88],
          "甘肃": [103.73, 36.03],
          "内蒙古": [111.65, 40.82],
          "新疆": [87.68, 43.77],
          "上海": [121.48, 31.22],
          "台湾": [121.30, 25.03],
          "北京": [116.46, 39.92],
          "天津": [117.2, 39.13],
          "海南": [110.35, 20.02],
          "香港": [114.17, 22.28],
          "宁夏": [106.27, 38.47],
          "青海": [101.74, 36.56],
          "西藏": [91.11, 29.97],
          "澳门": [113.54, 22.19],

        };

        var convertData = function(data) {
          var res = [];
          for (var i = 0; i < data.length; i++) {
            var geoCoord = geoCoordMap[data[i].name];
            if (geoCoord) {
              res.push({
                name: data[i].name,
                value: geoCoord.concat(data[i].value)
              });
            }
          }
          return res;
        };

        var convertedData = [
          convertData(data),
          convertData(data.sort(function(a, b) {
            return b.value - a.value;
          }).slice(0, 6))
        ];
        data.sort(function(a, b) {
          return a.value - b.value;
        })

        var selectedItems = [];
        var categoryData = [];
        var barData = [];
        //   var maxBar = 30;
        var sum = 0;
        var count = data.length;
        for (var i = 0; i < data.length; i++) {
          categoryData.push(data[i].name);
          barData.push(data[i].value);
          sum += data[i].value;
        }
        console.log(categoryData);
        console.log(sum + "   " + count)
        var option = {
          animation: true,
          animationDuration: 1000,
          animationEasing: 'cubicInOut',
          animationDurationUpdate: 1000,
          title: [{
            id: 'statistic',
            text: count ? '平均: ' + parseInt((sum / count).toFixed(4)) : '',
            right: 120,
            top: 40,
            width: 100,
            textStyle: {
              color: '#fff',
              fontSize: 16
            }
          }],
          toolbox: {
            iconStyle: {
              normal: {
                borderColor: '#fff'
              },
              emphasis: {
                borderColor: '#b1e4ff'
              }
            }
          },

          geo: {
            map: 'china',
            left: '10',
            right: '70%',
            center: [90.06, 33.67],
            //放大地图
            zoom: 1.8,
            label: {
              emphasis: {
                show: false
              }
            },
            roam: true,
            itemStyle: {
              normal: {
                areaColor: '#12235c',
                borderColor: '#2ab8ff',
                borderWidth: .5,
                shadowColor: 'rgba(0,54,255, 0.4)',
                shadowBlur: 200

              },
              emphasis: {

                label: {
                  color: "#fff"
                }

              }

            }
          },
          tooltip: {
            trigger: 'item'
          },
          //右边数据
          grid: {
            right: 40,
            top: 100,
            bottom: 40,
            width: '25%'
          },
          xAxis: {
            type: 'value',
            scale: true,
            position: 'top',
            boundaryGap: false,
            splitLine: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              margin: 2,
              textStyle: {
                color: '#aaa'
              }
            },
          },
          yAxis: {
            type: 'category',
            //  name: 'TOP 20',
            nameGap: 16,
            axisLine: {
              show: true,
              lineStyle: {
                color: '#ddd'
              }
            },
            axisTick: {
              show: false,
              lineStyle: {
                color: '#ddd'
              }
            },
            axisLabel: {
              interval: 0,
              textStyle: {
                color: '#ddd'
              }
            },
            data: categoryData
          },
          series: [{
            // name: 'pm2.5',
            type: 'scatter',
            coordinateSystem: 'geo',
            data: convertedData[0],
            symbolSize: function(val) {
              return Math.max(val[2] / 300, 8);
            },
            label: {
              normal: {
                formatter: '{b}',
                position: 'right',
                show: false
              },
              emphasis: {
                show: true
              }
            },
            itemStyle: {
              normal: {
                color: '#FF8C00',
                position: 'right',
                show: true
              }
            }
          }, {
            //  name: 'Top 5',
            type: 'effectScatter',
            coordinateSystem: 'geo',
            data: convertedData[0],
            symbolSize: function(val) {
              return Math.max(val[2] / 500, 8);
            },
            showEffectOn: 'render',
            rippleEffect: {
              brushType: 'stroke'
            },
            hoverAnimation: true,
            label: {
              normal: {
                formatter: '{b}',
                position: 'right',
                show: true
              }
            },
            itemStyle: {
              normal: {
                color: '#f4e925',
                shadowBlur: 50,
                shadowColor: '#EE0000'
              }
            },
            zlevel: 1
          }, {
            id: 'bar',
            zlevel: 2,
            type: 'bar',
            symbol: 'none',
            itemStyle: {
              normal: {
                color: '#ddb926'
              }
            },

            data: data
          }]
        };



        function renderBrushed(params) {
          var mainSeries = params.batch[0].selected[0];

          var selectedItems = [];
          var categoryData = [];
          var barData = [];
          var maxBar = 30;
          var sum = 0;
          var count = 0;

          for (var i = 0; i < mainSeries.dataIndex.length; i++) {
            var rawIndex = mainSeries.dataIndex[i];
            var dataItem = convertedData[0][rawIndex];
            var pmValue = dataItem.value[2];

            sum += pmValue;
            count++;

            selectedItems.push(dataItem);
          }

          selectedItems.sort(function(a, b) {
            //   return b.value[2] - a.value[2];
            return a.value - b.value;
          });

          for (var i = 0; i < Math.min(selectedItems.length, maxBar); i++) {
            categoryData.push(selectedItems[i].name);
            barData.push(selectedItems[i].value[2]);
          }

          this.setOption({
            yAxis: {
              data: categoryData
            },
            xAxis: {
              axisLabel: {
                show: !!count
              }
            },
            title: {
              id: 'statistic',
              text: count ? '平均: ' + (sum / count).toFixed(4) : ''
            },
            series: {
              id: 'bar',
              //        sort:'descending',
              data: barData
            }
          });
        }
        myChart.setOption(option);
      }
    },
    mounted() {
      this.getaddress()
      this.getEchartData()
    }
  }
</script>

<style scoped>
  .contontaa {
    z-index: 999;
    width: 7rem;
    height: 2.8rem;
    position: relative;
    top: -3rem;
    left: -0.2rem;
  }
</style>
