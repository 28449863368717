import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

//读取本地json
import VueResource from 'vue-resource'
Vue.use(VueResource)


//引用echarts
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts

//引入echatrs的水球图
import 'echarts-liquidfill'
 

 
 //引入中国地图
 import map from "../public/js/china.js"
 Vue.use(map)
 
//引入字体
import "./assets/font/fontless.css"

//引入axios
	import axios from 'axios'
	Vue.prototype.$axios = axios  //将axios绑定到vue的原型上

Vue.prototype.HOST = "/api"
 
 
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
