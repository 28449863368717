<template>
  <div class="contontaa">
 <div :style="{ width: '6rem', height: '2.9rem' }" ref="myChart"></div>
  </div>
</template> 
<script>  
  export default {
    data() {
      return {
              // 平台每月的金额统计：192.168.0.115:8001/api/admin/datacore/money_statistics
              // (注：xAxis：坐标，order_money：订单金额，pay_money：充值金额)
              xAxis:[],
              order_money:[],
              pay_money:[]
            
      }
    },
    methods: {
      money_statistics(){
        this.$axios({
          url:this.HOST+'api/admin/datacore/money_statistics',
          method:'get'
        }).then(res=>{
          console.log(res)
         this.xAxis=res.data.data.xAxis
         this. order_money=res.data.data.order_money
        this.pay_money=res.data.data.pay_money
        this. getEchartData();
        })
      },
      getEchartData() {
        var myChart = this.$echarts.init(this.$refs.myChart);
   var option = {
         title: {
    	        text: '2019年销售和主营业务收入对比',
    	        textStyle: {
    	        	 align: 'center',
    	            color: '#fff',
    	            fontSize: 10,
    	        },
    	        top: '1%',
    	        left: '25%',
    	    },
        grid: {
            top: "25%",
            bottom: "10%"//也可设置left和right设置距离来控制图表的大小
        },
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: "shadow",
                label: {
                    show: true
                }
            }
        },
        legend: {
            data: ["订单金额", "充值金额"],
            top: "15%",
            textStyle: {
                color: "#ffffff"
            }
        },
        xAxis: {
            data: this.xAxis ,
            axisLine: {
                show: true, //隐藏X轴轴线
                lineStyle: {
                            color: '#01FCE3'
                            }
            },
            axisTick: {
                show: true //隐藏X轴刻度
            },
            axisLabel: {
                show: true,
                textStyle: {
                    color: "#ebf8ac" //X轴文字颜色
                }
            },
             
        },
        yAxis: [{
                type: "value",
                name: "亿元",
                nameTextStyle: {
                    color: "#ebf8ac"
                },
                splitLine: {
                    show: false
                },
                axisTick: {
                    show: true
                },
                axisLine: {
                    show: true,
                    lineStyle: {
                                color: '#FFFFFF'
                                }
                },
                axisLabel: {
                    show: true,
                    textStyle: {
                        color: "#ebf8ac"
                    }
                },
                 
            },
            {
                type: "value",
                name: "同比",
                nameTextStyle: {
                    color: "#ebf8ac"
                },
                position: "right",
                splitLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                axisLine: {
                    show: false
                },
                axisLabel: {
                    show: true,
                    formatter: "{value} %", //右侧Y轴文字显示
                    textStyle: {
                        color: "#ebf8ac"
                    }
                }
            },
            {
                type: "value",
                gridIndex: 0,
                min: 50,
                max: 100,
                splitNumber: 8,
                splitLine: {
                    show: false
                },
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    show: false
                },
                splitArea: {
                    show: true,
                    areaStyle: {
                        color: ["rgba(250,250,250,0.0)", "rgba(250,250,250,0.05)"]
                    }
                }
            }
        ],
        series: [{
                name: "订单金额",
                type: "line",
                yAxisIndex: 1, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用
                smooth: true, //平滑曲线显示
                showAllSymbol: true, //显示所有图形。
                symbol: "circle", //标记的图形为实心圆
                symbolSize: 10, //标记的大小
                itemStyle: {
                    //折线拐点标志的样式
                    color: "#058cff"
                },
                lineStyle: {
                    color: "#058cff"
                },
                areaStyle:{
                    color: "rgba(5,140,255, 0.2)"
                },
                data:  this. order_money
            },
            {
                name: "充值金额",
                type: "bar",
                barWidth: 15,
                itemStyle: {
                    normal: {
                        color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: "#00FFE3"
                            },
                            {
                                offset: 1,
                                color: "#4693EC"
                            }
                        ])
                    }
                },
                data: this.pay_money
            }
        ]
    };
      
            var app = {
              currentIndex: -1,
            };
            setInterval(function () {
              var dataLen = option.series[0].data.length;
      
              // 取消之前高亮的图形
              myChart.dispatchAction({
                type: 'downplay',
                seriesIndex: 0,
                dataIndex: app.currentIndex
              });
              app.currentIndex = (app.currentIndex + 1) % dataLen;
              //console.log(app.currentIndex);
              // 高亮当前图形
              myChart.dispatchAction({
                type: 'highlight',
                seriesIndex: 0,
                dataIndex: app.currentIndex,
              });
              // 显示 tooltip
              myChart.dispatchAction({
                type: 'showTip',
                seriesIndex: 0,
                dataIndex: app.currentIndex
              });
            }, 1000);
            
       myChart.setOption(option);
     }      
    },
    mounted(){

      this.getEchartData()
      this.money_statistics()
    }
  }
</script>

<style scoped>
  .contontaa {
    z-index: 999;
    width: 6rem;
    height: 2.8rem;
    position: relative;
    top: -3rem;
  }
</style>