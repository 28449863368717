<template>
  <div class="contonr">
    <div class="topp">
      <middle></middle>
      <div class="no">
        <div class="no-hd" @click="chengzhen()">
          <div class="num">
            {{num1}}
          </div>
          <div class="textone" >
            全平台用户数量
          </div>
        </div>
        <div class="line"></div>
        <div class="no-bd" @click="feixing()">
          <div class="num">
            {{num2}}
          </div>
          <div class="texttwo" >
            全平台金额
          </div>
        </div>
      </div>

      <!-- 地图球行 -->
    </div>
    <div class='map'>
      <div class="map1" ></div>
      <div class="map2" :class="{'rotate360':showAnimate}"></div>
      <div class="map3" :class="{'rotate1':showAnimatd}"></div>
      <div class="map4">
        <mapp v-show="xianshi"></mapp>
        <CHENZHEN v-show='xianshione'></CHENZHEN>
      </div>
    </div>
  </div>
</template>

<script>
  import middle from "../middlejiao/middlejiao.vue"
  import mapp from "../eachers/mapecharts.vue"
  import CHENZHEN from "../eachers/chengzhen.vue"
  export default {
    components: {
      middle,mapp,CHENZHEN
    },
    data() {
      return {
        num1: 18796,
        num2: 11908,
          showAnimate: false,
          showAnimatd:false,
          xianshi:true,
          xianshione:false
      }
    },
    mounted() {
      this.showAnimate=true
      this.showAnimatd=true
    },
    created() {
      this.getnums()
    },
    methods:{
      getnums(){
        this.$axios({
                      url: this.HOST + '/api/admin/datacore/all_platform_user',
                      method: 'get',
                    }).then(res => {
                      console.log(res)
                      let ress =res.data.data
                       this.num1=ress.all_platform_user
                      this.num2 =ress.money_platform_sum
                      console.log(ress,1111)
                     
                        //res为后端传回来的数据
        })
      },
      // chengzhen(){
      //   this.xianshione= true;
      //   this.xianshi=false;
      // },
      // feixing(){
      //   this.xianshione= false;
      //   this.xianshi=true;
      // }
    }
  }
</script>

<style scoped>
  .contonr {
    width: 10.5rem;
    height: 9.7rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;

  }

  .topp {
    width: 100%;
    height: 22%;
    background-image: url('../../../public/images/line(1).png');
  }

  .line {
    height: 0.6rem;
    border: 0.0025rem rgb(0, 149, 255, 0.2) solid;
    margin-top: 0.50rem;
  }

  .no {
    width: 96%;
    height: 1.9rem;
    position: absolute;
    top: 0.014648rem;
    left: 0.07rem;
    display: flex;
    text-align: center;

  }

  .no-hd {
    width: 50%;
    height: 100%;
  }

  .no-bd {
    width: 50%;
    height: 100%;
  }

  .num {
    margin-top: 0.3rem;
    color: #ffeb7b;
    padding: 0.05rem 0;
    font-family: "electronicFont";
    font-weight: bold;
    font-size: 0.45rem;
  }

  .textone {
    position: absolute;
    left:1.799687rem;
    top: 1.079812rem;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 0.250391rem;
    color: white;
  }

  .texttwo {
    position: absolute;
    left: 6.85446rem;
    top: 1.079812rem;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
      font-size: 0.250391rem;
    color: white;
  }

  .map {
    width: 100%;
    height: 78%;
    position: relative;
  }

  .map1 {
    width: 6.541471rem;
    height: 6.541471rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url('../../../public/images/map.png');
    background-repeat: no-repeat;
    background-size: 100%;
    opacity: .3;
  }

  .map2 {
  width: 6.541471rem;
  height: 6.541471rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url('../../../public/images/lbx.png');
  background-repeat: no-repeat;
  background-size: 100%;
  opacity: 1;
  }
  .map3{
    width:6.541471rem;
    height: 6.541471rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url('../../../public/images/jt.png');
    background-repeat: no-repeat;
    background-size: 100%;
    opacity: .6;
  }
  .map4{
       position: absolute;
       top: 2.676056rem;
       left: 1.205008rem;
  }
 .rotate360 {
          animation: rotate360 10s linear infinite;
      } 
   .rotate1{
      animation: rotate1 10s linear infinite;
   }
    @keyframes rotate360 {
       from {
         transform: translate(-50%, -50%) rotate(0deg);
       }
       to {
         transform: translate(-50%, -50%) rotate(360deg);
       }
     }
     @keyframes rotate1 {
       from {
         transform: translate(-50%, -50%) rotate(0deg);
       }
       to {
         transform: translate(-50%, -50%) rotate(-360deg);
       }
     }

</style>
