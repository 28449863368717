<template>
  <div class="contontaa">
    <div :style="{ width: '6rem', height: '2.9rem' }" ref="myChart"></div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        // 192.168.0.115:8001/api/admin/datacore/user_channel_statistics

        app_user: [],
        mp_user: [],
        weixin_user: [],
        web_user: []
      }
    },
    methods: {
      user_channel_statistics() {
        this.$axios({
          url: this.HOST + 'api/admin/datacore/user_channel_statistics',
          method: 'get',
        }).then(res => {
          let data = res.data.data
          console.log(data, 111132321321)
          this.app_user = data.app_user //app用户
          this.mp_user = data.mp_user //小程序用户
          this.weixin_user = data.weixin_user //微信公众号用户
          this.web_user = data.web_user //H5用户
          this.getEchartData()
        })
      },
      getEchartData() {
        var myChart = this.$echarts.init(this.$refs.myChart);
        var scale = 1;
        var echartData = [{
          value: this.app_user,
          name: '用户'
        }, {
          value: this.mp_user,
          name: '小程序用户'
        }, {
          value: this.weixin_user,
          name: '微信公众号用户'
        }, {
          value: this.web_user,
          name: 'H5用户'
        }]
        var rich = {
          yellow: {
            color: "#ffc72b",
            fontSize: 30,
            padding: [5, 4],
            align: 'center'
          },
          total: {
            color: "#ffc72b",
            fontSize: 40,
            align: 'center'
          },
          white: {
            color: "#fff",
            align: 'center',
            fontSize: 14,
            padding: [21, 0]
          },
          blue: {
            color: '#49dff0',
            fontSize: 16,
            align: 'center'
          },
          hr: {
            borderColor: '#0b5263',
            width: '100%',
            borderWidth: 1,
            height: 0,
          }
        }
        var option = {
         
          title: {
            text: '各应用用户数量',
            left: 'center',
            top: '53%',
            padding: [24, 0],
            textStyle: {
              color: '#fff',
              fontSize: 10,
              align: 'center'
            }
          },
          legend: {
            selectedMode: false,
            formatter: function(name) {
              var total = 0; //各科正确率总和
              var averagePercent; //综合正确率
              echartData.forEach(function(value, index, array) {
                total += value.value;
              });
              return '{total|' + total + '}';
            },
            data: [echartData[0].name],
            // data: ['高等教育学'],
            // itemGap: 50,
            left: 'center',
            top: 'center',
            icon: 'none',
            align: 'center',
            textStyle: {
              color: "#fff",
              fontSize: 10,
              rich: rich
            },
          },
          series: [{
            name: '各应用用户数量',
            type: 'pie',
            radius:'60%',
            hoverAnimation: false,
            color: ['#c487ee', '#deb140', '#49dff0', '#034079', '#6f81da', '#00ffb4'],
            label: {
              normal: {
                formatter: function(params, ticket, callback) {
                  var total = 0; //考生总数量
                  var percent = 0; //考生占比
                  echartData.forEach(function(value, index, array) {
                    total += value.value;
                  });
                  percent = ((params.value / total) * 100).toFixed(1);
                  return '{white|' + params.name + '}\n{hr|}\n{yellow|' + params.value + '}\n{blue|' + percent +
                    '%}';
                },
                rich: rich
              },
            },
            labelLine: {
              normal: {
                length: 55 * scale,
                length2: 0,
                lineStyle: {
                  color: '#0b5263'
                }
              }
            },
            data: echartData
          }]
        };
        myChart.setOption(option);
      }
    },
    mounted() {
      this.getEchartData()
      this.user_channel_statistics()
    }
  }
</script>

<style scoped>
  .contontaa {
    z-index: 999;
    width: 6rem;
    height: 2.8rem;
    position: relative;
    top: -3rem;
  }
</style>
