<template>
  
</template>

<script>
// @ is an alias to /src


export default {
  name: "HomeView",
  components: {
    
  },
};
</script>
