<template>
  <div class="contontaa">
    <div :style="{ width: '6rem', height: '2.9rem' }" ref="myChart"></div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        xAxis: [],
        order_money: [],
        pay_money: []
      }
    },
    methods: {
      money_statistics() {
        this.$axios({
          url: this.HOST + 'api/admin/datacore/money_statistics',
          method: 'get'
        }).then(res => {
          console.log(res)
          this.xAxis = res.data.data.xAxis
          this.order_money = res.data.data.order_money
          this.pay_money = res.data.data.pay_money
          this.getEchartData();
        })
      },
      getEchartData() {
        var myChart = this.$echarts.init(this.$refs.myChart);
        var xData = this.xAxis
        var lastYearData = this.order_money
        var thisYearData = this.pay_money
        var timeLineData = [1];
        let legend = ['订单金额', '充值金额'];
        let textColor = "#fff";
        let lineColor = "rgba(255,255,255,0.2)";
        let colors = [{
            borderColor: "rgba(227,161,96,1)",
            start: "rgba(227,161,96,0.8)",
            end: "rgba(227,161,96,0.3)"
          },
          {
            borderColor: "rgba(0,222,255,1)",
            start: "rgba(0,222,255,0.3)",
            end: "rgba(0,222,255,0.8)"
          },
        ];
        let borderData = [];
        let scale = 2;
        borderData = xData.map(item => {
          return scale;
        });
        var option = {
          baseOption: {
            // backgroundColor: background,
            timeline: {
              show: false,
              top: 0,
              data: []
            },
            legend: {
              top: '0%',
              right: '5%',
              itemWidth: 20,
              itemHeight: 5,
              // itemGap: 343,
              icon: 'horizontal',
              textStyle: {
                color: '#ffffff',
                fontSize: 10,
              },
              data: legend
            },
            grid: [{
              show: false,
              left: '5%',
              top: '10%',
              bottom: '8%',
              containLabel: true,
              width: '37%'
            }, {
              show: false,
              left: '51%',
              top: '10%',
              bottom: '8%',
              width: '0%'
            }, {
              show: false,
              right: '2%',
              top: '10%',
              bottom: '8%',
              containLabel: true,
              width: '37%'
            }],
            xAxis: [{
              type: 'value',
              inverse: true,
              min: 0,
              max: 2000,
              axisLine: {
                show: false
              },
              axisTick: {
                show: false
              },
              position: 'top',
              axisLabel: {
                show: true,
                color: textColor
              },
              splitLine: {
                show: true,
                lineStyle: {
                  color: lineColor
                }
              },
            }, {
              gridIndex: 1,
              show: false
            }, {
              gridIndex: 2,
              axisLine: {
                show: false
              },
              axisTick: {
                show: false
              },
              position: 'top',
              axisLabel: {
                show: true,
                color: textColor
              },
              splitLine: {
                show: true,
                lineStyle: {
                  color: lineColor
                }
              },
            }],
            yAxis: [
              {
                type: 'category', //左标轴类型
                inverse: true,
              
                position: 'right',
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: lineColor
                  }
                },

                axisTick: {
                  show: false
                },
                axisLabel: {
                  show: false
                },
                data: xData
              }, {
                gridIndex: 1,
                type: 'category',
                inverse: true,
                position: 'left',
                axisLine: {
                  show: false
                },
                axisTick: {
                  show: false
                },
                axisLabel: {
                  show: true,
                  padding: [30, 0, 0, 0],
                  textStyle: {
                    color: '#ffffff',
                    fontSize: 10
                  },
                  align: "center"

                },
                data: xData.map(function(value) {
                  return {
                    value: value,
                    textStyle: {
                      align: 'center'
                    }
                  }
                })
              }, {
                gridIndex: 2,
                type: 'category',
                inverse: true,
                position: 'left',
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: lineColor
                  }
                },
                axisTick: {
                  show: false
                },
                axisLabel: {
                  show: false

                },
                data: xData
              }
            ],
            series: []

          },
          options: []
        }

        option.baseOption.timeline.data.push(timeLineData[0])
        option.options.push({
          series: [{
              name: "订单金额",
              type: "bar",
              barWidth: 15,
              stack: "1",
              itemStyle: {
                normal: {
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                      offset: 0,
                      color: colors[0].start
                    },
                    {
                      offset: 1,
                      color: colors[0].end
                    }
                  ]),
                }
              },
              label: {
                normal: {
                  show: false,
                }
              },
              data: lastYearData,
              animationEasing: "elasticOut"
            },
            {
              name: "订单金额",
              type: "bar",
              barWidth: 25,
              stack: "1",
              itemStyle: {
                normal: {
                  color: colors[0].borderColor

                }
              },
              data: borderData
            },
            {
              name: "充值金额",
              type: "bar",
              stack: "2",
              barWidth: 15,
              xAxisIndex: 2,
              yAxisIndex: 2,
              itemStyle: {
                normal: {
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                      offset: 0,
                      color: colors[1].start
                    },
                    {
                      offset: 1,
                      color: colors[1].end
                    }
                  ]),
                }
              },
              label: {
                normal: {
                  show: false,
                }
              },
              data: thisYearData,
              animationEasing: "elasticOut"
            },
            {
              name: "充值金额",
              type: "bar",
              xAxisIndex: 2,
              yAxisIndex: 2,
              barWidth: 25,
              stack: "2",
              itemStyle: {
                normal: {
                  color: colors[1].borderColor

                }
              },
              data: borderData
            },
          ]
        });


        myChart.setOption(option);
      }
    },
    mounted() {
      this.money_statistics()
      this.getEchartData()
    }
  }
</script>

<style scoped>
  .contontaa {
    z-index: 999;
    width: 6rem;
    height: 2.8rem;
    position: relative;
    top: -3rem;
  }
</style>
