<template>
  <div class="contontaa">
    <div :style="{ width: '6rem', height: '2.9rem' }" ref="myChart"></div>
  </div>
</template>
<script>
  export default {
    data() {
      return {

      }
    },
    methods: {
      getEchartData() {
        var myChart = this.$echarts.init(this.$refs.myChart);
        var option = {
            title:{
                text:'用户人口数量',
               left:'center',
                textStyle:{
                       //文字颜色
                       color:'#fff',
                       //字体风格,'normal','italic','oblique'
                       fontStyle:'normal',
                       //字体粗细 'normal','bold','bolder','lighter',100 | 200 | 300 | 400...
                       fontWeight:'bold',
                       //字体系列
                       fontFamily:'sans-serif',
                       //字体大小
               　　　　 fontSize:18
                   }
            },
            series: [{
                type: 'liquidFill',//类型
                animation: true,
                waveAnimation: true,
                data: [0.76, 0.70],
                color: ['#49d088', '#38b470', '#2aaf66'],
                center: ['25%', '60%'],
                waveLength: '60%',
                amplitude: 8,
                radius: '70%',
                label: {
                    normal: {
                        formatter: function() {
                            return '世界\n\n76%人口';
                        },
                        textStyle: {
                            fontSize: 22,
                            color: '#44c078'
                        },
                        position: ['50%', '30%']
                    }
                },
                outline: {
                    itemStyle: {
                        borderColor: '#49d088',
                        borderWidth: 5
                    },
                    borderDistance: 0
                },
                itemStyle: {
                    normal: {
                        backgroundColor: '#fff'
                    }
                }
            }, {
                animation: true,
                waveAnimation: true,
        
                type: 'liquidFill',
                data: [0.66, 0.6, 0.5],
                color: ['#eb594b', '#e44d46', '#dc403f'],
                center: ['75%', '60%'],
                radius: '70%',
                amplitude: 8,
                label: {
                    normal: {
                        formatter: function() {
                            return '中国\n\n66%人口'
                        },
                        textStyle: {
                            fontSize: 22,
                            color: '#e35140'
                        },
                        position: ['50%', '30%']
                    }
                },
                outline: {
                    itemStyle: {
                        borderColor: '#e35140',
                        borderWidth: 5
                    },
                    borderDistance: 0
                },
                itemStyle: {
                    normal: {
                        backgroundColor: '#fff'
                    }
                }
            }]
        };
        
        myChart.on("click",function(e){alert("省钱兄科技！");})


        myChart.setOption(option);
      }
    },
    mounted() {
      this.getEchartData()
    }
  }
</script>

<style scoped>
  .contontaa {
    z-index: 999;
    width: 6rem;
    height: 2.8rem;
    position: relative;
    top: -3rem;
  }
</style>
